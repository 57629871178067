import AlternateEmailIcon from '@mui/icons-material/AlternateEmail';
// ? Icons
import FacebookIcon from '@mui/icons-material/Facebook';
import InstagramIcon from '@mui/icons-material/Instagram';
import LinkedInIcon from '@mui/icons-material/LinkedIn';
import LocationOnIcon from '@mui/icons-material/LocationOn';
import TwitterIcon from '@mui/icons-material/Twitter';
import { styled } from '@mui/material';
// ? Material UI
import Box from '@mui/material/Box';
import Container from '@mui/material/Container';
import Divider from '@mui/material/Divider';
import Grid from '@mui/material/Grid';
import IconButton from '@mui/material/IconButton';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemButton from '@mui/material/ListItemButton';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import Stack from '@mui/material/Stack';
import Typography from '@mui/material/Typography';
import Image from 'next/image';
// ? Next Js
import Link from 'next/link';
import React from 'react';
import Dot from '../../assets/dot-bg.png';
// ? Assets
import Logo from '../logo';

const Section = styled('section')(({ theme }) => ({
  background: theme.palette.grey.A100,
  position: 'relative',
  overflow: 'hidden',
}));

interface IProps {
  company: ICompany;
  footer: IFooter;
}

const Footer: React.FC<IProps> = ({ company, footer }) => {
  const logo: any = company?.logo?.media;
  return (
    <Section>
      <Box
        sx={{
          position: 'absolute',
          bottom: 0,
          height: 700,
          width: '100%',
          overflow: 'hidden',
        }}
      >
        <Image src={Dot} layout="responsive" alt="Hero dot" />
      </Box>
      <Container>
        <Grid
          container
          sx={{ py: { xs: 4, md: 8 }, position: 'relative', zIndex: 2 }}
          spacing={{ xs: 2, md: 4 }}
        >
          <Grid item xs={12} sm={6} md={3}>
            <Stack spacing={2}>
              <Box sx={{ width: 180 }}>
                <Logo data={logo} />
              </Box>
              <Typography color="#dedede" component="a">
                {company.description}
              </Typography>
            </Stack>
          </Grid>

          {footer.section.map((el, i) => (
            <Grid item xs={12} sm={6} md={3} key={el._id}>
              <Typography component="h1" variant="h3" color="white">
                {el.title}
              </Typography>
              <Stack spacing={1} sx={{ mt: 4 }}>
                {el.footerLink.map((_, i) => (
                  <Link href={`${_.link}`} passHref key={_._id}>
                    <Typography color="#dedede" component="a">
                      {_.label}
                    </Typography>
                  </Link>
                ))}
              </Stack>
            </Grid>
          ))}

          <Grid item xs={12} sm={6} md={3}>
            <Typography component="h1" variant="h3" color="white">
              {footer.address}
            </Typography>

            <List aria-label="address" dense>
              {company.address?.map((el, i) => (
                <ListItem disableGutters key={el._id}>
                  <ListItemIcon>
                    <LocationOnIcon sx={{ color: 'white' }} />
                  </ListItemIcon>
                  <ListItemText primary={el.label} sx={{ color: 'white' }} />
                </ListItem>
              ))}

              <Link
                href={`mailto:${company.coordonnees.email}?subject=From Website - Your subject`}
                passHref
              >
                <ListItemButton disableGutters>
                  <ListItemIcon>
                    <AlternateEmailIcon sx={{ color: 'white' }} />
                  </ListItemIcon>
                  <ListItemText
                    primary={company.coordonnees.email}
                    sx={{ color: 'white', wordBreak: 'break-all' }}
                  />
                </ListItemButton>
              </Link>
            </List>
          </Grid>
        </Grid>

        <Divider sx={{ borderColor: '#dedede' }} />
        <Stack
          justifyContent="space-between"
          alignItems="center"
          direction={{ xs: 'column-reverse', md: 'row' }}
          spacing={2}
          sx={{ py: 2 }}
        >
          <Typography color="white" component="p" textAlign="center">
            Copyright &copy; {new Date().getFullYear()}{' '}
            <Typography color="secondary" component="span">
              Uptodate Developers
            </Typography>
            . All rights reserved
          </Typography>
          <Stack direction="row" spacing={1}>
            <a
              href={company.RS?.facebook}
              target="_blank"
              title="Facebook of uptodate developers"
              rel="noopener noreferrer"
            >
              <IconButton>
                <FacebookIcon sx={{ color: 'white' }} aria-label="facebook" />
              </IconButton>
            </a>
            <a
              href={company.RS?.instagram}
              target="_blank"
              title="Instagram of uptodate developers"
              rel="noopener noreferrer"
            >
              <IconButton>
                <InstagramIcon sx={{ color: 'white' }} aria-label="instagram" />
              </IconButton>
            </a>
            <a
              href={company.RS?.twitter}
              target="_blank"
              title="Twitter of uptodate developers"
              rel="noopener noreferrer"
            >
              <IconButton>
                <TwitterIcon sx={{ color: 'white' }} aria-label="twitter" />
              </IconButton>
            </a>
            <a
              href={company.RS?.linkedin}
              target="_blank"
              title="LinkedIn of uptodate developers"
              rel="noopener noreferrer"
            >
              <IconButton>
                <LinkedInIcon sx={{ color: 'white' }} aria-label="linkedIn" />
              </IconButton>
            </a>
          </Stack>
        </Stack>
      </Container>
    </Section>
  );
};

export default Footer;
